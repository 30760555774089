<template lang="pug">
    list-items-part-5(:itemsData="itemsData", dense)
</template>

<script>
import ListItemsPart5 from './ListItems/ListItems.vue';

// eslint-disable-next-line
const getIcon = (src) => require(`../../../../../assets/images/articles/5/list_items/top/${src}.svg`);

export default {
    name: 'list_items_top',
    components: {
        ListItemsPart5,
    },
    computed: {
        itemsData() {
            return [
                {
                    icon: getIcon('1'),
                    text: 'Бессонница',
                },
                {
                    icon: getIcon('2'),
                    text: 'Головная боль',
                },
                {
                    icon: getIcon('3'),
                    text: 'Боль в&nbsp;правом боку',
                },
                {
                    icon: getIcon('4'),
                    text: 'Тошнота',
                },
                {
                    icon: getIcon('5'),
                    text: 'Расстройство пищеварения',
                },
                {
                    icon: getIcon('6'),
                    text: 'Аллергические реакции',
                },
                {
                    icon: getIcon('7'),
                    text: 'Общая слабость',
                },
                {
                    icon: getIcon('8'),
                    text: 'Ухудшение слуха и&nbsp;зрения',
                },
            ];
        },
    },
};
</script>
